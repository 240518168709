@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    .card-box-shadow {
        box-shadow: 0 0.5rem 1rem 0 rgba(5, 5, 5, 0.2);
    }

    fieldset {
        border: 0;
    }

    .cta-hover:hover {
        box-shadow: inset 0 0 0 0.1rem #fff !important;
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

    h1.heading-article {
        @apply tw-font-teko;
        @apply tw-font-semibold;
        @apply tw-text-custom-black;
        @apply tw-max-w-[12ch];
        @apply tw-leading-[4.5rem];
        @apply lg:tw-leading-[5rem];
        font-size: 5.39rem;
    }

    h2.heading-article {
        @apply tw-font-teko;
        @apply tw-font-semibold;
        @apply tw-text-custom-black;
        @apply tw-text-5xl;
        @apply tw-tracking-normal;
    }

    h3.heading-article {
        @apply tw-font-teko;
        @apply tw-font-semibold;
        @apply tw-text-custom-black;
        @apply tw-text-4xl;
        @apply tw-tracking-normal;
    }

    h4.heading-article {
        @apply tw-font-teko;
        @apply tw-font-normal;
        font-size: 1.75rem;
        line-height: 1.594rem;
        letter-spacing: 0.159rem;
        font-weight: 500;
        @apply tw-text-custom-red;
        @apply tw-mb-3;
    }

    h5.heading-article {
        @apply tw-font-teko;
        @apply tw-text-base;
        @apply tw-font-normal;
        @apply tw-tracking-normal;
        @apply tw-text-custom-red;
    }

    h1.heading-article-section {
        @apply tw-font-teko;
        @apply tw-text-custom-black;
        @apply tw-font-semibold;
        @apply tw-text-6xl;
        @apply tw-tracking-normal;
    }

    h2.heading-article-section {
        @apply tw-font-teko;
        @apply tw-text-custom-black;
        @apply tw-font-semibold;
        @apply tw-text-[2.7rem];
        @apply tw-tracking-normal;
    }

    h3.heading-article-section {
        @apply tw-font-teko;
        @apply tw-text-custom-black;
        @apply tw-font-semibold;
        @apply tw-text-[2.65rem];
        @apply tw-max-w-[40ch];
        line-height: 2.5rem;
        letter-spacing: 0.063rem;
    }

    h4.heading-article-section {
        @apply tw-font-teko;
        @apply tw-text-lg;
        @apply tw-font-normal;
        @apply tw-tracking-normal;
        @apply tw-text-custom-red;
    }

    h5.heading-article-section {
        @apply tw-font-teko;
        @apply tw-text-base;
        @apply tw-font-normal;
        @apply tw-tracking-normal;
        @apply tw-text-custom-red;
    }

    h1.heading-centers-by-state,
    h2.heading-centers-by-state,
    h3.heading-centers-by-state,
    h4.heading-centers-by-state,
    h5.heading-centers-by-state {
        @apply tw-font-teko;
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;
        @apply md:tw-leading-[1.15px];
    }

    h1.heading-centers-by-state {
        font-size: 3rem;
        line-height: 3rem;
        @apply md:tw-text-[5.9rem];
        @apply md:tw-leading-[4.7rem];
    }

    h2.heading-centers-by-state {
        font-size: 2.8rem;
        line-height: 2.8rem;
        @apply md:tw-text-[5.7rem];
        @apply md:tw-leading-[4.56rem];
    }

    h3.heading-centers-by-state {
        font-size: 2.6rem;
        line-height: 2.6rem;
        @apply md:tw-text-[5.5rem];
        @apply md:tw-leading-[4.3rem];
    }

    h4.heading-centers-by-state {
        font-size: 2.4rem;
        line-height: 2.4rem;
        @apply md:tw-text-[5.3rem];
        @apply md:tw-leading-[4.1rem];
    }

    h5.heading-centers-by-state {
        font-size: 2.2rem;
        line-height: 2.2rem;
        @apply md:tw-text-[5.1rem];
        @apply md:tw-leading-[3.9rem];
    }


    h1.heading-content {
        @apply tw-font-teko;
        font-size: 3.6rem;
        line-height: 3.9rem;
        @apply tw-font-semibold;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        @apply md:tw-text-[5.7rem];
        @apply md:tw-leading-[4.6rem];
        @apply md:tw-max-w-[75rem];
        @apply md:tw-my-0;
        @apply md:tw-mx-auto;
    }

    h2.heading-content {
        @apply tw-font-teko;
        font-size: 3.6rem;
        line-height: 3.6rem;
        @apply tw-font-bold;
        text-transform: uppercase;
        @apply tw-tracking-normal;
        @apply md:tw-max-w-[75rem];
        @apply md:tw-my-0;
        @apply md:tw-mx-auto;
    }

    h3.heading-content {
        @apply tw-font-teko;
        @apply tw-text-5xl;
        @apply tw-font-bold;
        @apply tw-tracking-normal;
        @apply md:tw-max-w-[75rem];
        @apply md:tw-my-0;
        @apply md:tw-mx-auto;
    }

    h4.heading-content {
        @apply tw-font-teko;
        @apply tw-text-[1.7rem];
        @apply tw-font-normal;
        @apply tw-uppercase;
        @apply tw-tracking-[0.15em];
        @apply tw-leading-[2.6rem];
        @apply tw-text-custom-red;
        @apply tw-mb-[1.7rem];
        @apply md:tw-max-w-[75rem];
        @apply md:tw-mt-0;
        @apply md:tw-mx-auto;
    }

    h5.heading-content {
        @apply tw-font-teko;
        @apply tw-text-2xl;
        @apply tw-font-normal;
        @apply tw-uppercase;
        @apply tw-tracking-[0.15em];
        @apply tw-text-custom-red;
        @apply tw-mb-[1.7rem];
        @apply md:tw-max-w-[75rem];
        @apply md:tw-mt-0;
        @apply md:tw-mx-auto;
    }

    div.heading-content {
        @apply md:tw-pt-[2rem];
        @apply md:tw-max-w-[75rem];
        @apply md:tw-my-0;
        @apply md:tw-mx-auto;
    }

    h1.heading-default {
        @apply tw-text-[3.6rem];
        @apply tw-font-semibold;
        @apply tw-tracking-[0.02em];
        @apply tw-leading-[3.9rem];
        @apply md:tw-text-[5.7rem];
        @apply tw-leading-[4.6rem];
    }

    h2.heading-default {
        @apply tw-text-4xl;
        @apply tw-font-semibold;
        @apply tw-tracking-[0.02em];
        @apply md:tw-text-5xl;
    }

    h3.heading-default {
        @apply tw-text-[2.8rem];
        @apply tw-font-semibold;
        @apply tw-tracking-[0.02em];
        @apply tw-leading-[2.4rem];
    }

    h4.heading-default {
        @apply tw-text-3xl;
        @apply tw-font-semibold;
        @apply tw-tracking-[0.02em];
        @apply md:tw-text-2xl;
    }

    h5.heading-default {
        @apply tw-text-xl;
        @apply tw-font-semibold;
        @apply tw-tracking-[0.02em];
        @apply md:tw-text-2xl;
    }

    h1.heading-feature {
        color: #ffffff;
        @apply tw-text-8xl;
        @apply tw-font-semibold;
        margin-top: 0;
    }

    h2.heading-feature {
        color: #ffffff;
        font-size: 5.39rem;
        line-height: .75em;
        @apply tw-font-semibold;
        margin-top: 0;
    }

    h3.heading-feature {
        color: #ffffff;
        @apply tw-text-6xl;
        @apply tw-font-semibold;
        margin-top: 0;
    }

    h4.heading-feature {
        color: #ed1c24;
        @apply tw-leading-none;
        margin-bottom: 10px;
    }

    h5.heading-feature {
        color: #ed1c24;
        @apply tw-text-2xl;
        @apply tw-leading-none;
        margin-bottom: 10px;
    }

    h1.heading-geocenter-search,
    h2.heading-geocenter-search,
    h3.heading-geocenter-search,
    h4.heading-geocenter-search,
    h5.heading-geocenter-search {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-weight: 600;
        letter-spacing: 1px;
        @apply lg:tw-tracking-[1.15px];
    }

    h1.heading-geocenter-search {
        font-size: 3.2rem;
        line-height: 3.2rem;
        @apply lg:tw-text-[4.0rem];
        @apply lg:tw-leading-[3.6rem];
    }

    h2.heading-geocenter-search {
        font-size: 3rem;
        line-height: 3rem;
        @apply lg:tw-text-[3.8rem];
        @apply lg:tw-leading-[3.4rem];
    }

    h3.heading-geocenter-search {
        font-size: 2.8rem;
        line-height: 2.8rem;
        @apply lg:tw-text-[3.6rem];
        @apply lg:tw-leading-[3.24rem];        
    }

    h4.heading-geocenter-search {
        font-size: 2.6rem;
        line-height: 2.6rem;
        @apply lg:tw-text-[3.4rem];
        @apply lg:tw-leading-[3.0rem];
    }

    h5.heading-geocenter-search {
        font-size: 2.4rem;
        line-height: 2.4rem;
        @apply lg:tw-text-[3.2rem];
        @apply lg:tw-leading-[2.8rem];
    }

    h1.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-size: 2.8rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        font-weight: 600;
        padding-bottom: 1rem;
        @apply lg:tw-text-[5.7rem];
        @apply lg:tw-leading-[80%];
        @apply lg:tw-tracking-[0.02em];
        @apply lg:tw-text-white;
    }

    h2.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-size: 2.6rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        font-weight: 600;
        padding-bottom: 1rem;
        @apply lg:tw-text-[5.5rem];
        @apply lg:tw-leading-[4.4rem];
        @apply lg:tw-tracking-[0.02em];
        @apply lg:tw-text-white;
    }

    h3.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-size: 2.4rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        font-weight: 600;
        padding-bottom: 1rem;
        @apply lg:tw-text-[5.3rem];
        @apply lg:tw-leading-[4.2rem];
        @apply lg:tw-tracking-[0.02em];
        @apply lg:tw-text-white;
    }

    h4.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-red;
        @apply tw-text-2xl;
        @apply tw-font-semibold;
        @apply lg:tw-text-3xl;
        @apply lg:tw-text-white;
    }

    h5.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-red;
        @apply tw-text-xl;
        @apply tw-font-semibold;
        @apply lg:tw-text-2xl;
        @apply lg:tw-text-white;
    }

    div.heading-hero-dark {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        @apply tw-font-normal;
        @apply lg:tw-text-white;
    }

    h1.heading-navigation {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        @apply lg:tw-leading-[3.3rem];
        @apply lg:tw-py-0;
    }

    h2.heading-navigation {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-weight: 600;
        font-size: 3.0rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        @apply lg:tw-leading-[3.3rem];
        @apply lg:tw-py-0;
    }

    h3.heading-navigation {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 100%;
        letter-spacing: 0.036em;
        text-transform: uppercase;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        @apply lg:tw-leading-[3.3rem];
        @apply lg:tw-py-0;
    }

    h4.heading-navigation {
        @apply tw-font-teko;
        @apply tw-text-custom-red;
        font-weight: 400;
        font-size: 1.7rem;
        letter-spacing: 0.15em;
        line-height: 2.6rem;
        text-transform: uppercase;
    }

    h5.heading-navigation {
        @apply tw-font-teko;
        @apply tw-text-custom-red;
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 0.15em;
        line-height: 2.6rem;
        text-transform: uppercase;
    }

    h1.heading-nearest-center {
        @apply tw-font-bold;
        @apply tw-text-6xl;
        @apply tw-tracking-normal;
    }

    h2.heading-nearest-center {
        @apply tw-font-bold;
        @apply tw-text-[3.6rem];
        @apply tw-tracking-normal;
        @apply tw-leading-[3.6rem];
    }

    h3.heading-nearest-center {
        @apply tw-font-bold;
        @apply tw-text-5xl;
        @apply tw-tracking-normal;
    }

    h4.heading-nearest-center {
        @apply tw-tracking-normal;
        @apply tw-text-4xl;
        @apply tw-font-bold;
    }

    h5.heading-nearest-center {
        @apply tw-tracking-normal;
        @apply tw-text-3xl;
        @apply tw-font-bold;
    }

    h1.heading-staff {
        @apply tw-font-teko;
        @apply tw-font-semibold;
        @apply tw-text-custom-black;
        @apply tw-max-w-[12ch];
        @apply tw-leading-[4.5rem];
        @apply lg:tw-leading-[5rem];
        font-size: 5.39rem;
        @apply tw-whitespace-nowrap;
    }

    h2.heading-staff {
        @apply tw-font-teko;
        @apply tw-font-semibold;
        @apply tw-text-custom-black;
        font-size: 5.39rem;
        @apply tw-tracking-normal;
    }

    h3.heading-staff {
        @apply tw-font-teko;
        @apply tw-text-custom-black;
        @apply tw-font-semibold;
        font-size: 1.75rem;
        line-height: 1.75rem;
        letter-spacing: 0.063rem;
    }

    h4.heading-staff {
        @apply tw-font-teko;
        @apply tw-font-normal;
        font-size: 1.75rem;
        line-height: 1.594rem;
        letter-spacing: 0.159rem;
        font-weight: 500;
        @apply tw-text-custom-red;
        @apply tw-mb-3;
    }

    h5.heading-staff {
        @apply tw-font-teko;
        @apply tw-text-base;
        @apply tw-font-normal;
        @apply tw-tracking-normal;
        @apply tw-text-custom-red;
    }

    h1.heading-twoColumnArticle,
    h2.heading-twoColumnArticle,
    h3.heading-twoColumnArticle,
    h4.heading-twoColumnArticle,
    h5.heading-twoColumnArticle {
        @apply tw-font-teko;
        @apply tw-text-custom-dark;
        font-weight: 600;
        letter-spacing: 1.15px;
    }

    h1.heading-twoColumnArticle {
        font-size: 4.0rem;
        line-height: 3.2rem;
    }

    h2.heading-twoColumnArticle {
        font-size: 3.8rem;
        line-height: 3.4rem;
    }

    h3.heading-twoColumnArticle {
        font-size: 3.6rem;
        line-height: 3.24rem;        
    }

    h4.heading-twoColumnArticle {
        font-size: 3.4rem;
        line-height: 3.0rem;
    }

    h5.heading-twoColumnArticle {
        font-size: 3.2rem;
        line-height: 2.8rem;
    }

    i.chevron-left {
        background: url("/images/chevron-left.png") no-repeat center;
        display: inline-block;
        height: 11px;
        width: 6px;
    }

    i.clock {
        background: url("/images/icon-clock.png") no-repeat center;
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    i.info-phone {
        background: url("/images/icon-phone.png") no-repeat center;
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    i.phone {
        background: transparent url("/images/phone.png") no-repeat center;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
    }

    i.location-target {
        background: url("/images/icon-location-pin.png") no-repeat center;
        display: inline-block;
        height: 24px;
        width: 24px;
    }

    i.map {
        background: url("/images/icon-map.png") no-repeat center;
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    i.map-pin {
        background: url("/images/map-pin.png") no-repeat center;
        display: inline-block;
        height: 22px;
        width: 20px;
    }

    i.target {
        background: url("/images/icon-target.png") no-repeat center;
        display: inline-block;
        height: 22px;
        width: 20px;
    }

    input[type="text"].search {
        background-image: url('/images/search-dark.png');
        background-position-x: 95%;
        background-position-y: center;
        background-repeat: no-repeat;
        font-family: 'Arial';
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.8rem;
        padding-bottom: 1.5rem;
        padding-left: 1.4rem;
        padding-right: 5.8rem;
        padding-top: 1.5rem;
        @apply tw-text-custom-dark;
        @apply tw-tracking-wide;
    }

    input[type="text"][data-loading="true"] {
        cursor: wait;
        background-image: url('/images/loading.gif');
    }

        input[type="text"][data-loading="true"]:hover {
            background-color: #f7f6f6 !important;
        }
}

@layer utilities {

    .color-theme-darkbackground {
        background-color: #231f20;
    }

    .color-theme-default {
        background-color: transparent;
    }

    .color-theme-lightgraybackground {
        background-color: #f7f6f6;
    }

    .color-theme-mediumgraybackground {
        background-color: #F1EFEF;
    }

    .color-theme-whitebackground {
        background-color: #ffffff;
    }

    .form-thankyou-footer h1,
    .form-thankyou-footer h2 {
        font-size: 3.6rem;
        font-weight: 700;
        letter-spacing: 0em;
        line-height: 3.6rem;
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
    }

    .form-thankyou-footer h3,
    .form-thankyou-footer h4,
    .form-thankyou-footer h5 {
        font-size: 2.8rem;
        font-weight: 600;
        letter-spacing: 0.02em;
        line-height: 2.4rem;
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
    }

    .fieldset-red-theme-label span {
        color: #ed1c24;
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: 0.15em;
        line-height: 2.6rem;
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
    }

    .fieldset-red-theme-label input[type="text"],
    .fieldset-red-theme-label input[type="email"],
    .fieldset-red-theme-label input[type="tel"] {
        border-radius: 0.2rem;
        background-color: #f7f6f6;
        transition: background-color 0.25s;
        color: #aea8a5;
        line-height: 1.4;
        padding: 0.8rem 1.4rem;
        appearance: none;
        border: 3px solid #808080 !important;
        margin: 1.7rem 0 20px 0;
        font-weight: 100;
        overflow: visible;
        font-size: 100%;
        width: 100%;
    }

    .fieldset-red-theme-label select {
        margin: 0 0 20px 0;
        border: 3px solid #808080;
        border-radius: 0.2rem;
        width: 100%;
        background-color: #f7f6f6;
        transition: background-color 0.25s;
        color: #aea8a5;
        line-height: 1.4;
        padding: 0.8rem 1.4rem;
        appearance: none;
        font-family: inherit;
        font-size: 100%;
    }

        .fieldset-red-theme-label select option {
            background-color: #f7f6f6;
            color: #6c6463;
        }

    .fieldset-red-theme-label .descriptive {
        font-style: italic;
        margin-top: -5px;
        margin-bottom: -5px;
        color: #808080;
        width: 100%;
    }

    .fieldset-red-theme-label textarea {
        height: 200px;
        width: 100%;
        color: #000000;
        border: 3px solid #808080 !important;
        border-radius: 0.2rem;
        background-color: #f7f6f6;
        transition: background-color 0.25s;
        line-height: 1.4;
        padding: 0.8rem 1.4rem;
        appearance: none;
        font-family: inherit;
        overflow: auto;
        font-size: 100%;
        margin: 0;
    }

    .fieldset-red-theme-label a {
        color: #0076a8;
        text-decoration: none;
        transition: color 0.2s;
    }

    h-sub {
        display: block;
    }

        h-sub.heading-article {
            font-family: Teko, sans-serif;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            letter-spacing: 0em;
            color: #ed1c24;
        }

        h-sub.heading-article-section {
            font-family: Teko, sans-serif;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            letter-spacing: 0em;
            color: #ed1c24;
        }

        h-sub.heading-content {
            font-family: Teko, sans-serif;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0em;
        }

        h-sub.heading-default {
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-weight: 600;
            letter-spacing: 0.02em;
        }

        h-sub.heading-feature {
            font-size: 1.5rem;
            line-height: 2rem;
            color: #ed1c24;
            line-height: 1;
            margin-bottom: 10px;
            font-style: italic;
        }

        h-sub.heading-hero-dark {
            font-family: Teko, sans-serif;
            color: #ed1c24;
            font-weight: 700;
            font-size: 14px;
        }

        h-sub.heading-navigation {
            color: #ed1c24;
            font-size: 1.4rem;
            letter-spacing: 0.15em;
            line-height: 2.6rem;
            text-transform: uppercase;
        }

        h-sub.heading-nearest-center {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 0em;
        }

    .topstickynav {
        position: fixed;
    }

    .topstickynav-transition {
        transition: margin-top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
    }

    .tw-arrow-down::after {
        font-family: "icomoon" !important;
        content: "c";
        transform: rotate(90deg);
        display: inline-block;
        margin-left: 1.42rem;
    }

    .tw-arrow-up::after {
        font-family: "icomoon" !important;
        content: "c";
        transform: rotate(-90deg);
        display: inline-block;
        margin-left: 1.42rem;
    }

    .tw-arrow-off::after {
        display: none;
    }

    .tw-button {
        border: 1px solid transparent;
        border-radius: 3rem;
        display: block;
        font-size: 1.2rem;
        font-weight: 900;
        line-height: 1.4;
        max-width: 27rem;
        padding: 1rem 2rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.25s, background-color 0.25s, box-shadow 0.25s;
    }

    .tw-red-white-button {
        @apply tw-text-white;
        @apply tw-bg-custom-red;
        @apply tw-border-custom-red;
    }

        .tw-red-white-button:hover {
            @apply tw-text-custom-red;
            @apply tw-bg-white;
            @apply tw-border-custom-red;
        }

    .tw-dark-white-button {
        @apply tw-bg-custom-dark;
        @apply tw-text-white;
        @apply tw-border-custom-dark;
    }

        .tw-dark-white-button:hover {
            @apply tw-bg-white;
            @apply tw-text-custom-dark;
            @apply tw-border-custom-dark;
        }

    .tw-red-call-button,
    .tw-red-call-button:focus {
        @apply tw-text-white;
        @apply tw-bg-custom-red;
        font-family: Arial;
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        padding: .5rem 2rem;
        line-height: 2.0rem;
        max-width: 27rem;
        text-align: center;
        border: 0;
        border-radius: 3rem;
        cursor: pointer;
        transition: color 0.25s, background-color 0.25s, box-shadow 0.25s;
        text-decoration: none !important;
        outline: unset;
        outline-offset: unset;
    }

        .tw-red-call-button::before {
            font-size: 1.5rem;
            vertical-align: middle;
            margin-right: 1.8rem;
            content: "b";
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
        }

        .tw-red-call-button:hover {
            background-color: #c61017;
            color: #fff !important;
        }

    .tw-black-message-button {
        @apply tw-text-white;
        @apply tw-bg-custom-black;
        font-family: Arial;
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 1rem 2rem;
        line-height: 2rem;
        max-width: 27rem;
        text-align: center;
        border: 0;
        border-radius: 3rem;
        cursor: pointer;
        transition: color 0.25s, background-color 0.25s, box-shadow 0.25s;
        text-decoration: none !important;
    }

        .tw-black-message-button::before {
            font-size: 1.5rem;
            vertical-align: middle;
            margin-right: 1rem;
            content: "d";
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
        }

        .tw-black-message-button:hover {
            color: #fff !important;
            box-shadow: inset 0 0 0 0.1rem #fff;
        }

    .tw-ddlNav-focus {
        @apply tw-text-custom-red;
        @apply tw-border-custom-red !important;
    }

    @media (min-width: 768px) {
        h-sub .heading-content {
            max-width: 75rem;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
        }

        h-sub.heading-default {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
    /*Workarounds - To Be Removed - Pickett*/

    .promoAvail:empty {
        display: none;
    }

    comp-press comp-container > div .title, a {
        color: #0076a8;
    }

    comp-content.content-component a.red-filled {
        color: #ffffff;
    }

    ol, ul, menu {
        list-style: inside;
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 24px;
    }

        ul ul {
            list-style-type: circle;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }

    h2, sudo-h2, .heading-2 {
        font-size: 3.6rem;
        font-weight: 700;
        letter-spacing: 0em;
        line-height: 3.6rem;
    }

    @media (min-width: 768px) {
        h1, sudo-h1, .heading-1 {
            font-size: 5.7rem;
            font-weight: 600;
            letter-spacing: 0.02em;
            line-height: 4.6rem;
        }
    }

    @media (min-width: 768px) {
        h1 + *, sudo-h1 + *, .heading-1 + * {
            margin-top: 4rem !important;
        }
    }
}

.promodealer {
    border: solid #aaa;
    border-width: 0 0 1px;
    margin-bottom: 20px
}

    .promodealer[dealer-selector] > header:not(.expandedp) + section {
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0
    }

    .promodealer[dealer-selector] > section {
        box-sizing: border-box;
        max-height: 500px;
        overflow: hidden;
        transition: all .25s
    }

    .promodealer > header {
        background: #FFFFFF;
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        padding: 5px 40px 5px 10px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #aea8a5;
    }

        .promodealer > header:not(textarea) {
            height: 38px
        }

        .promodealer > header:after, form:not(.gsc-search-box) .promodealer > header:before {
            background: #000;
            content: "";
            height: 2px;
            margin-top: -1px;
            position: absolute;
            right: 14px;
            top: 50%;
            transition: transform .2s;
            width: 11px
        }

        .promodealer > header:not(.expandedp):after {
            transform: rotate(-90deg)
        }

    .promodealer > section {
        border-color: #efefef;
        border-style: solid;
        border-width: 0 1px;
        padding: 20px
    }

        .promodealer > section li {
            display: block;
            margin-top: 10px;
        }

            .promodealer > section li * {
                cursor: pointer;
                display: block;
            }

            .promodealer > section li.selected {
                font-weight: 700;
            }

        .promodealer > section label.name {
            color: #6f87a6;
        }

        .promodealer > section label.address {
            color: #979797;
        }

        .promodealer > section label.distance {
            color: #979797;
        }
